<!-- 个人中心 -->
<template>
  <div>
    <div class="top-header">
      <div class="head-nav index-banner-bg headNav">
        <div class="container navCon">
          <div class="nav-left float-l">
            <h1 class="animated zoomIn">
              <router-link to="/">
                <img
                  src="../../assets/Uploads/2022-04-29/626b9fd680a65.png"
                  width="122"
                  height="44"
                  alt=""
                />
              </router-link>
            </h1>
          </div>
          <div class="nav-right">
            <p class="top-user-info-2">
              <router-link :to="{ name: 'personal' }">
                <a href="" class="btn border-0 text-black"
                  >{{ username }} 的主页</a
                >
              </router-link>
              <router-link to="/">
                <a href="" class="btn btn-register border-r5" @click="exit"
                  >退出</a
                >
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="page-notice">
      <div class="container">
        <div class="notice-side">
          <ul>
            <li>
              <router-link
                class="news-item-children"
                :to="{ name: 'personaldata', query: { username } }"
              >
                个人资料
              </router-link>
            </li>
            <li>
              <router-link
                class="news-item-children"
                :to="{ name: 'order', query: { username } }"
              >
                我的订单
              </router-link>
            </li>
            <li>
              <router-link
                class="news-item-children"
                :to="{ name: 'coupon', query: { username } }"
              >
                我的优惠券
              </router-link>
            </li>
          </ul>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <Sidebar />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import { removeToken } from "@/utils/auth.js";
export default {
  data() {
    return {
      username: "",
    };
  },
  created() {
    this.username = this.$route.query.username;
  },
  methods: {
    exit() {
      removeToken("Admin-Token");
      localStorage.removeItem("islogin");
      localStorage.removeItem("username");
      window.collectEvent("config", {
        user_unique_id: null,
      });
    },
  },
  components: { Footer, Sidebar },
};
</script>

<style scoped>
.router-link-active {
  color: #059fff;
}
.page-notice {
  overflow: hidden;
  background-color: #ffffff;
}
.notice-side ul {
  margin: 0px;
}
@import "../../assets/static/template/Home/Zkeys/PC/Static/statics/css/front/about.css";
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/common/module/about/ab_comm.css";
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/common/common/common.css";
</style>
